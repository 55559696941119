.app{
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
 }
 footer{
	margin-top: auto;
 }
.active{
	color: #fff;
}

.ant-drawer-body{
padding-top:10px !important;
}
ul{
margin-bottom: 0 !important;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: #313846 !important;
}
.ant-carousel .slick-prev::before {
  color: #313846 !important;
}
.slick-arrow.slick-prev {
  font-size: 35px !important;
}
.ant-carousel .slick-next::before {
  color: #313846 !important;
}
.slick-arrow.slick-next {
  font-size:35px !important;
}
.css-dev-only-do-not-override-85b9zw.ant-carousel .slick-prev{
  inset-inline-start: 0px;
  z-index: 1;
}
.css-dev-only-do-not-override-85b9zw.ant-carousel .slick-next {
  inset-inline-end: 15px;
}

@media screen and (max-width: 476px){
  .slick-arrow.slick-prev {
    font-size:20px !important;
  }
  .slick-arrow.slick-next {
    font-size:20px !important;
  }
  .css-dev-only-do-not-override-85b9zw.ant-carousel .slick-next {
    inset-inline-end: 5px;
  }
  .css-dev-only-do-not-override-85b9zw.ant-carousel .slick-prev{
    inset-inline-start: 5px;
    z-index: 1;
  }
}

.custom-line-height {
  line-height: 2.2rem;
}
.custom-line-height-mobil {
  line-height: none;
}

.fon{
	background-image: url('./images/fon/fon.webp');
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}
.fon2{
	background-image: url('./images/fon/background.webp');
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.fon:after, .fon2:after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	background: #000000;
	opacity: .6;
	z-index: -1;
}

.text-shadow {
	text-shadow: 2px 2px 0 rgba(0,0,0,0.4);
 }